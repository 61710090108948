export const elementsSelector = ".element";
export const rootSelector = "#root";

export function selectElements() {
  return selectAll(elementsSelector);
}

export function selectRoot() {
  return select(rootSelector);
}

export function select(
  selector: string,
  element: ParentNode = document
): HTMLElement {
  return element.querySelector(selector) as HTMLElement;
}

export function selectAll(
  selector: string,
  element: ParentNode = document
): HTMLElement[] {
  return Array.from(element.querySelectorAll(selector));
}
