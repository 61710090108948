import { selectElements, selectRoot } from "../utility/select";
import { string2number } from "../utility/string2number";
import { px2rem } from "../utility/px2rem";

//// state

let resizeElement: HTMLElement;
let navigateElement: HTMLElement;

//// entry

export function resize(element: HTMLElement) {
  element.style.width = `${element.dataset.sizeX}rem`;
  element.style.height = `${element.dataset.sizeY}rem`;
}

export function resizeElements(): void {
  const elements = selectElements();
  elements.forEach(resize);
}

export function resizeable(element: HTMLElement) {
  navigateElement = selectRoot();
  element.addEventListener("pointerdown", handleResizeBegin);
}

export function resizeableElements() {
  navigateElement = selectRoot();
  selectElements().forEach(resizeable);
}

//// event listeners

export function handleResizeBegin(this: HTMLElement, event: PointerEvent) {
  if (
    ![
      // shift + left
      event.button === 0 && !event.altKey && !event.ctrlKey && event.shiftKey,
      // right
      event.button === 2 && !event.altKey && !event.ctrlKey && !event.shiftKey,
    ].some((b) => b)
  ) {
    return;
  }

  const element = event.currentTarget as HTMLElement;

  // prevent text selection
  event.preventDefault();

  resizeElement = element;

  element.removeEventListener("pointerdown", handleResizeBegin);
  document.addEventListener("pointermove", handleResizeContinue);
  document.addEventListener("pointerup", handleResizeEnd);
}

export function handleResizeContinue(
  this: Document,
  event: PointerEvent
): void {
  const dataset = resizeElement.dataset;

  // prevent text selection
  event.preventDefault();

  resizeElement.dataset.sizeX = `${
    px2rem(event.clientX) -
    string2number(navigateElement.dataset.positionX) -
    string2number(dataset.positionX) -
    px2rem(window.innerWidth / 2)
  }`;
  resizeElement.dataset.sizeY = `${
    px2rem(event.clientY) -
    string2number(navigateElement.dataset.positionY) -
    string2number(dataset.positionY)
  }`;

  resize(resizeElement);
}

export function handleResizeEnd(this: Document, _event: PointerEvent) {
  document.removeEventListener("pointermove", handleResizeContinue);
  document.removeEventListener("pointerup", handleResizeEnd);
  resizeElement.addEventListener("pointerdown", handleResizeBegin);
}
