import { selectElements } from "../utility/select";
import { string2number } from "../utility/string2number";
import { px2rem } from "../utility/px2rem";

//// entry

export function drag(element: HTMLElement) {
  element.style.transform = `translate(
    ${element.dataset.positionX}rem,
    ${element.dataset.positionY}rem)`;
}

export function dragElements(): void {
  selectElements().forEach(drag);
}

export function dragable(element: HTMLElement) {
  element.addEventListener("pointerdown", handleDragBegin);
}

export function dragableElements() {
  selectElements().forEach(dragable);
}

//// event listeners

// global state referring to currently dragged element
let dragElement: HTMLElement = document.body;

export function handleDragBegin(this: HTMLElement, event: PointerEvent) {
  if (
    ![
      // alt + left
      event.button === 0 && event.altKey && !event.ctrlKey && !event.shiftKey,
      // middle
      event.button === 1 && !event.altKey && !event.ctrlKey && !event.shiftKey,
    ].some((b) => b)
  ) {
    return;
  }

  const element = event.currentTarget as HTMLElement;
  const dataset = element.dataset;

  // prevent text selection
  event.preventDefault();

  dragElement = element;

  dataset.dragBeginElementX = `${dataset.positionX}`;
  dataset.dragBeginElementY = `${dataset.positionY}`;
  dataset.dragBeginPointerX = `${px2rem(event.clientX)}`;
  dataset.dragBeginPointerY = `${px2rem(event.clientY)}`;

  element.removeEventListener("pointerdown", handleDragBegin);
  document.addEventListener("pointermove", handleDragContinue);
  document.addEventListener("pointerup", handleDragEnd);
}

export function handleDragContinue(this: Document, event: PointerEvent): void {
  const dataset = dragElement.dataset;

  // prevent text selection
  event.preventDefault();

  dataset.positionX = `${
    string2number(dataset.dragBeginElementX) -
    string2number(dataset.dragBeginPointerX) +
    px2rem(event.clientX)
  }`;

  dataset.positionY = `${
    string2number(dataset.dragBeginElementY) -
    string2number(dataset.dragBeginPointerY) +
    px2rem(event.clientY)
  }`;

  drag(dragElement);
}

export function handleDragEnd(this: Document, _event: PointerEvent) {
  document.removeEventListener("pointermove", handleDragContinue);
  document.removeEventListener("pointerup", handleDragEnd);
  dragElement.addEventListener("pointerdown", handleDragBegin);
}
