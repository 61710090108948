import { selectRoot } from "../utility/select";
import { drag } from "./drag";
import { px2rem } from "../utility/px2rem";
import { string2number } from "../utility/string2number";

//// state

// save navigated element globally because some handlers listen on document
let navigateElement: HTMLElement;

//// entry

export function navigateRoot() {
  drag(selectRoot());
}

export function navigatableRoot() {
  navigateElement = selectRoot();

  document.body.addEventListener("pointerdown", handleNavigateBegin);
}

//// event listeners

export function handleNavigateBegin(this: Element, event: PointerEvent) {
  if (
    ![
      // left
      event.button === 0 && !event.altKey && !event.ctrlKey && !event.shiftKey,
    ].some((b) => b)
  ) {
    return;
  }

  // prevent text selection
  event.preventDefault();

  // this is needed for chromium-based mobile-browsers. it marks all subsequent
  // pointer-events with the pointer-id.
  document.body.setPointerCapture(event.pointerId);

  const { dataset } = navigateElement;

  dataset.navigateBeginElementX = `${dataset.positionX}`;
  dataset.navigateBeginElementY = `${dataset.positionY}`;
  dataset.navigateBeginPointerX = `${px2rem(event.clientX)}`;
  dataset.navigateBeginPointerY = `${px2rem(event.clientY)}`;

  document.body.removeEventListener("pointerdown", handleNavigateBegin);
  document.body.addEventListener("pointermove", handleNavigateContinue);
  document.body.addEventListener("pointerup", handleNavigateEnd);
}

export function handleNavigateContinue(
  this: Element,
  event: PointerEvent
): void {
  const { dataset } = navigateElement;

  // prevent text selection
  event.preventDefault();

  dataset.positionX = `${
    string2number(dataset.navigateBeginElementX) -
    string2number(dataset.navigateBeginPointerX) +
    px2rem(event.clientX)
  }`;

  dataset.positionY = `${
    string2number(dataset.navigateBeginElementY) -
    string2number(dataset.navigateBeginPointerY) +
    px2rem(event.clientY)
  }`;

  drag(navigateElement);
}

export function handleNavigateEnd(this: Element, event: PointerEvent) {
  document.body.releasePointerCapture(event.pointerId);

  document.body.removeEventListener("pointermove", handleNavigateContinue);
  document.body.removeEventListener("pointerup", handleNavigateEnd);
  document.body.addEventListener("pointerdown", handleNavigateBegin);
}
