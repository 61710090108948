import { select, selectAll } from "./select";

export function customizeAudios() {
  selectAll(".element.audio").forEach(customizeAudio);
}

export function customizeAudio(element: HTMLElement) {
  const audio = select("audio", element) as HTMLAudioElement;
  audio.style.display = "none";

  function addButton(name: string, handleClick: (event: MouseEvent) => void) {
    const button = document.createElement("div");
    button.onclick = handleClick;
    button.classList.toggle(name, true);
    element.appendChild(button);
  }

  addButton(
    "rewind",
    () => (audio.currentTime = Math.min(audio.duration, audio.currentTime - 10))
  );
  addButton("play", (event: MouseEvent) => {
    const { classList } = event.currentTarget as HTMLElement;

    if (classList.contains("play")) {
      audio.play();
    } else {
      audio.pause();
    }

    classList.toggle("play");
    classList.toggle("pause");
  });
  addButton(
    "forward",
    () => (audio.currentTime = Math.max(0, audio.currentTime + 10))
  );
}
